import React from 'react';

const closeDonateForm = () => {
  const donateForm = document.getElementById('donate-form');
  const donateTab = document.getElementById('project-support');
  const tabList = document.getElementsByClassName('tab');
  Array.from(tabList).forEach((el) => { el.style.display = 'flex' })
  donateForm.style.visibility = 'hidden';
  donateTab.style.display = 'none';
}

const DonateForm = () => {
    return (
        <form id="donate-form" className="yandex-form" method="POST" action="https://yoomoney.ru/quickpay/confirm.xml">
            <p className="close-btn" onClick={ closeDonateForm }>&times;</p>
            <input type="hidden" name="receiver" value="410015757768507"/>
            <input type="hidden" name="successURL" value="https://radiomyata.ru"/>
            <input type="hidden" name="formcomment" value="RadioMyata Donate"/>
            <input type="hidden" name="short-dest" value="RadioMyata Donate"/>
            <input type="hidden" name="targets" value="RadioMyata Donate"/>
            <input type="hidden" name="quickpay-form" value="donate"/>
            <div className="sum">
                <span>Сумма</span><br/>
                <input type="number" name="sum" min="2" max="15000" defaultValue="100" data-type="number"/><br/>
            </div>
            <div className="comment">
                <span>Комментарий</span><br/>
                <textarea name="comment" maxLength="200" rows="3"></textarea><br/>
            </div>
            <div className="pay-var">
                <label><input type="radio" name="paymentType" value="PC" defaultChecked/>&nbsp;&nbsp;ЮMoney / ЮМани</label><br/>
                <label><input type="radio" name="paymentType" value="AC"/>&nbsp;&nbsp;Банковская карта</label><br/>
            </div>
            <div className="form-buttons">
                <a onClick={ closeDonateForm }>Назад</a>
                <button type="submit" >Отправить</button>
            </div>
        </form>
    )
}
export default DonateForm;
