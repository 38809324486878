import React from 'react';
import yandex_img from '../../images/yandex.svg';
import telegram_img from '../../images/telegram.svg';
import instagram_img from '../../images/instagram.svg';
import vk_img from '../../images/vk.svg';
import spotify_img from '../../images/spotify.svg';
import youtube_img from '../../images/youtube.svg';


function IconLayout(_props) {

  function menuActive() {
    var menuBtn = document.getElementsByClassName('menu_button');
    var menu = document.getElementsByClassName('menu');
    for(let btn of menuBtn) {
      if(btn.className === 'menu_button') { btn.className = 'menu_button active'; }
      else if(btn.className === 'menu_button active') { btn.className = 'menu_button'; }
    }
    for(let a of menu) {
      if(a.className === 'menu') {  a.className = 'menu active'; }
      else if(a.className === 'menu active') { a.className = 'menu'; }
    }
  }
  return (
    <div className='nav'>
      <p className='menu_button'><span onClick={ menuActive }>СОЦСЕТИ</span><span onClick={ menuActive }>&times;</span></p>
      <ul className="menu">
        <li><a className='soc-icon' href='https://music.yandex.ru/users/shaldin.voice/playlists' target='_blank' rel="noreferrer"><img id='yandex' src={yandex_img} alt='Yandex.Music' width='85%' /></a></li>
        <li><a className='soc-icon' href='https://t.me/RadioMyata' target='_blank' rel="noreferrer"><img id='telegram' src={telegram_img} alt='Telegram' width='85%' /></a></li>
        <li><a className='soc-icon' href='https://instagram.com/radiomyata' target='_blank' rel="noreferrer"><img id='instagram' src={instagram_img} alt='Instagram' width='85%' /></a></li>
        <li><a className='soc-icon' href='https://vk.com/radiomyata' target='_blank' rel="noreferrer"><img id='vk' src={vk_img} alt='VK' width='85%' /></a></li>
        <li><a className='soc-icon' href='https://open.spotify.com/user/31b7rfatuqf7lc76thiudm5bxxuy?si=qwg2F6WzRBu6639jpB1RSA' target='_blank' rel="noreferrer"><img id='spotify' src={spotify_img} alt='Spotify' width='85%' /></a></li>
        <li><a className='soc-icon' href='https://www.youtube.com/@radiomyata' target='_blank' rel="noreferrer"><img id='youtube' src={youtube_img} alt='YouTube' width='85%' /></a></li>
      </ul>
    </div>
  );
}

export default IconLayout;
