import React, {createContext, useReducer, useEffect} from 'react';
import Reducer from './reducer'
import axios from 'axios';
import MetaTags from 'react-meta-tags';

const initialState = {
  stream: 'myata',
  nowPlaying: false,
  volume: '1',
  audio: new Audio('https://radio.dline-media.com/myata'),
  trackInfo: {
    'myata':      {artist: 'STOP WAR IN UKRAINE', track: 'radio myata'},
    'gold':       {artist: 'STOP WAR IN UKRAINE', track: 'radio myata'},
    'myata_hits': {artist: 'STOP WAR IN UKRAINE', track: 'radio myata'}
  }
};

const Store = ({children}) => {
    const [state, dispatch] = useReducer(Reducer, initialState);

    const updateTracksInfo = () => {
      axios.get('https://drh-api.dline-media.com/api/statistics?filter%5Borganization_id%5D=7')
      .then(function(response) {
        let result = parse_tracks(response.data.data);
        if (Object.keys(result).length === 0) return;
        dispatch({ trackInfo: result, lastUse: new Date() })
      });
      setTimeout(updateTracksInfo, 5000);
    }

    const parse_tracks = (data) => {
      let result = {};
      Object.values(data).forEach((el) => {
        el = el.streams[0];
        let pref = el.mount;
        if (pref) {
          let now_playing = el.last_song.title;
          if (now_playing) {
            now_playing = now_playing.split(' - ');
            if (now_playing.length == 1) now_playing.unshift(null)
            result[pref] = { artist: now_playing[0], track: now_playing[1] };
          } else {
            result[pref] = { artist: null, track: null };
          }
        }
      });
      return result;
    }

    useEffect(() => {
      state.audio.volume = state.volume;
    });

    useEffect(() => {
      updateTracksInfo();
    }, []);

    const getSiteTitle = () => {
      if (state.nowPlaying) {
        const trackInfo = state.trackInfo[state.stream];
        if (trackInfo.artist != null) {
          return trackInfo.artist + ' - ' + trackInfo.track;
        } else {
          return 'Радио Мята';
        }
      } else {
        return 'Радио Мята';
      }
    }

    return (
        <Context.Provider value={[state, dispatch]}>
          <MetaTags>
            <title>{getSiteTitle()}</title>
          </MetaTags>
            {children}
        </Context.Provider>
    )
};

export const Context = createContext(initialState);
export default Store;
