import React from 'react';
import ReactDOM from 'react-dom';
import Header from './components/header/header';
import Player from './components/player/player';
import Footer from './components/footer/footer';
import './index.css';
import Store from './store';
import HttpsRedirect from 'react-https-redirect';

function App() {
  return (
    <HttpsRedirect>
      <React.StrictMode>
        <div className='body'>
          <Store>
            <Header />
            <div className="container">
                <Player />
            </div>
          </Store>
          <Footer />
        </div>
      </React.StrictMode>
    </HttpsRedirect>
  );
}

ReactDOM.render(
  <App />,
  document.getElementById('root')
);
