import React from 'react';
import './header.css';
import Logo from './logo';
import IconLayout from './iconlayout';
import IconLayoutMobile from "./iconlayout-mobile";

function Header(_props) {
  return (
    <header className='header'>
        <div className='container'>
            <Logo />
            <IconLayoutMobile />
            <IconLayout />
        </div>
    </header>
  );
}

export default Header;
