const Reducer = (state, action) => {
  if (action.nowPlaying != null) {
    return {
      ...state,
      nowPlaying: action.nowPlaying
    };
  } else if (action.stream != null) {
    return {
      ...state,
      stream: action.stream
    };
  } else if (action.volume != null) {
    return {
      ...state,
      volume: action.volume
    };
  } else if (action.trackInfo != null) {
    return {
      ...state,
      trackInfo: action.trackInfo,
      lastUse: action.lastUse
    };
  } else {
    return state;
  };
};

export default Reducer;
