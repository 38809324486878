import React from 'react';
import yandex_img from '../../images/yandex.svg';
import telegram_img from '../../images/telegram.svg';
import instagram_img from '../../images/instagram.svg';
import vk_img from '../../images/vk.svg';
import spotify_img from '../../images/spotify.svg';
import youtube_img from '../../images/youtube.svg';

function IconLayout(_props) {
  return (
    <div className='icon-layout'>
      <a className='soc-icon' href='https://music.yandex.ru/users/shaldin.voice/playlists' target='_blank' rel="noreferrer"><img id='yandex' src={yandex_img} alt='Yandex.Music' /></a>
      <a className='soc-icon' href='https://t.me/RadioMyata' target='_blank' rel="noreferrer"><img id='telegram' src={telegram_img} alt='Telegram' /></a>
      <a className='soc-icon' href='https://instagram.com/radiomyata' target='_blank' rel="noreferrer"><img id='instagram' src={instagram_img} alt='Instagram' /></a>
      <a className='soc-icon' href='https://vk.com/radiomyata' target='_blank' rel="noreferrer"><img id='vk' src={vk_img} alt='VK'/></a>
      <a className='soc-icon' href='https://open.spotify.com/user/31b7rfatuqf7lc76thiudm5bxxuy?si=qwg2F6WzRBu6639jpB1RSA' target='_blank' rel="noreferrer"><img id='spotify' src={spotify_img} alt='Spotify' /></a>
      <a className='soc-icon' href='https://www.youtube.com/@radiomyata' target='_blank' rel="noreferrer"><img id='youtube' src={youtube_img} alt='YouTube' /></a>
    </div>
  );
}

export default IconLayout;
