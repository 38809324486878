import React from 'react';
import './footer.css';
import appstore_img from '../../images/appstore.svg';
import playstore_img from '../../images/playstore.svg';
import boosty_img from '../../images/boosty.svg';
import donate_button from '../../images/donate.svg'


const links = [
  { link: 'https://apps.apple.com/ru/app/%D1%80%D0%B0%D0%B4%D0%B8%D0%BE-%D0%BC%D1%8F%D1%82%D0%B0/id1254896500', link_img: appstore_img, alt: 'App Store' },
  { link: 'https://play.google.com/store/apps/details?id=dlinemedia.radioplayer.myata', link_img: playstore_img, alt: 'Google Play' }
]
const toggleDonateForm = () => {
  const donateForm = document.getElementById('donate-form');
  const donateTab = document.getElementById('project-support');
  const tabList = document.getElementsByClassName('tab');

  if (donateTab.style.display === 'flex') {
    Array.from(tabList).forEach((el) => { el.style.display = 'flex' })
    donateForm.style.visibility = 'hidden';
    donateTab.style.display = 'none';
  } else {
    Array.from(tabList).forEach((el) => { el.style.display = 'none' })
    donateForm.style.visibility = 'visible';
    donateTab.style.display = 'flex';
  }
}
const Footer = () => {
  return (
    <div className='footer'>
        <div className="container">
            <div className='footer_links'>
                {links.map((link) => {
                    return <a href={link.link} className='store_link' target='_blank' rel="noreferrer"><img src={link.link_img} alt={link.alt}></img></a>
                })}
            </div>
            <div className='donate_button' onClick={ toggleDonateForm }>
                <img src={ donate_button } alt='Донат'></img>
            </div>
            <div className='boosty_link'>
                <a href='https://boosty.to/myata' target='_blank' rel="noreferrer"><img src={boosty_img} alt='Мятный бусти'></img></a>
            </div>
        </div>

    </div>
  )
}

export default Footer;
