import React, { useContext } from 'react';
import { Context } from '../../store'
import './player.css';
import play_btn_img from '../../images/play-button.svg';
import pause_btn_img from '../../images/pause-button.svg';
import DonateForm from './donate_form';

const PlayerWrapper = (_props) => {
  const [state, dispatch] = useContext(Context);

  const playRadio = () => {
    const air = state.audio;
    air.volume = state.volume;
    air.src = 'https://radio.dline-media.com/' + state.stream;

    if (state.nowPlaying) {
      air.pause();
    } else {
      air.load();
      air.play();
    }
    dispatch({ nowPlaying: !state.nowPlaying })
  }

  const changeRadioVolume = () => {
    const volume_el = document.getElementById('volume-control');
    dispatch({ volume: volume_el.value / 100 });
  }

  return (
    <div className='player-wrapper'>
      <DonateForm />
      <div className='player'>
        <div className='player-control'>
          <img onClick={playRadio} className='play-pause-button' src={state.nowPlaying ? pause_btn_img : play_btn_img} alt='play button'/>
          {/* <input onChange={changeRadioVolume} id='volume-control' name='volume' type='range' min='0' max='100' defaultValue={state.volume * 100}></input> */}
        </div>
        <div className='track-info'>
          <span className='track-info-artist'>{state.trackInfo[state.stream]['artist'] || 'STOP WAR IN UKRAINE'}</span>
          <span className='track-info-title'>{state.trackInfo[state.stream]['track'] || 'radio myata'}</span>
        </div>
      </div>
    </div>
  )
}

export default PlayerWrapper;
