import React, { useContext, useEffect } from 'react';
import './player.css';
import PlayerWrapper from './player_wrapper';
import {Context} from '../../store'
import myata_bg from './../../images/bg1.png'
import gold_bg from './../../images/bg2.png'
import xtra_bg from './../../images/bg3.png'


const Player = (_props) => {
  const imageList = [myata_bg, gold_bg, xtra_bg]

  useEffect(() => {
    imageList.forEach((image) => {
      new Image().src = image;
    });
  });

  const [state, dispatch] = useContext(Context);

  const tabs = [
    { name: 'Myata', stream: 'myata', zIndex: 15, bg: imageList[0] },
    { name: 'Gold', stream: 'gold', zIndex: 14, bg: imageList[1] },
    { name: 'xtra', stream: 'myata_hits', zIndex: 13, bg: imageList[2] },
  ]

  const tabClick = (e) => {
    const tabs_elements = document.getElementsByClassName('tab');
    const air = state.audio
    let i = 1;

    for (let tab of tabs_elements) {
      if (e.target === tab) {
        tab.className = 'tab active';
        tab.style = 'z-index: 15;';
        dispatch({ stream: tab.id })
        air.src = 'https://radio.dline-media.com/' + tab.id;
        if (state.nowPlaying) {
          air.play();
        }
        for (let tab_obj of tabs) {
          if (tab.id === tab_obj.stream) {
            document.body.style.backgroundImage = 'url(' + tab_obj.bg + ')'
          }
        }
      } else {
        tab.className = 'tab ';
        tab.style = 'z-index: ' + (15 - i);
      }
      i += 1;
    };
  }

  return (
    <div className='center-wrapper'>

      <div className='tab-list'>
        <div id='project-support' className='project-support'>Поддержать проект</div>
        {tabs.map((tab) => {
          return <div id={tab.stream} onClick={tabClick} className={'tab ' + (tab.stream === state.stream ? 'active' : '')} style={{zIndex: tab.zIndex}} value={tab.name} data-bg={tab.bg}>{tab.name}</div>
        })}
      </div>

      <PlayerWrapper />
    </div>
  );
}

export default Player;
