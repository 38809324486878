import React, { useContext } from 'react';
import logo_img from '../../images/logo.png';
import {Context} from '../../store'

function Logo(_props) {

  const [state] = useContext(Context);

  return (
    <div style={{textAlign: 'center'}}>
      <img id='logo' data={logo_img} alt='Logo' src={logo_img} className={state.nowPlaying ? 'spin' : ''} />
    </div>
  )
}

export default Logo;
